/* You can add global styles to this file, and also import other style files */

$primary-color:#793DC7;
$primary-color-progress:#f9a000;
$primary-color-recheck:#e85a5a;
$primary-color-complete:#5ca2e8;
$primary-color-booked:#5ca2e8;
$white-color:#fff;

@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap);

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(assets/fonts/inter-light.woff2) format("woff2"), url(assets/fonts/inter-light.woff) format("woff")
}

;

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/inter-regular.woff2) format("woff2"), url(assets/fonts/inter-regular.woff) format("woff")
}

;

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(assets/fonts/inter-medium.woff2) format("woff2"), url(assets/fonts/inter-medium.woff) format("woff")
}

;

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(assets/fonts/inter-bold.woff2) format("woff2"), url(assets/fonts/inter-bold.woff2) format("woff")
}

;

.authentication-bg {
  background-image: url(assets/images/authentication-bg.jpg);
}

 html {
	scroll-behavior: smooth;
}
body[data-sidebar=dark] .vertical-menu,
body[data-sidebar=dark] .navbar-brand-box,
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
	background: #793DC7 !important;
}
body[data-sidebar=dark] #sidebar-menu ul li a i,
body[data-sidebar=dark] #sidebar-menu ul li a,
body[data-sidebar=dark] .menu-title {
	color: #fff;
	opacity: 1;
}

/*****  start Checkbox *****/

.checkbox-box {
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
}

.checkbox-box span {
  font-size: 0px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  display: inline-block;
}

.checkbox-box span:after {
  top: 0px;
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #c1c1c1;
  background-color: $white-color;
  position: absolute;
  left: 0px;
}

.checkbox-box input {
  position: absolute;
  width: 20px !important;
  left: 0px;
  opacity: 0;
  z-index: +9999;
  height: 20px;
  cursor: pointer;
  margin: 0px !important;
}

.checkbox-box .checkbox-box input[type=checkbox]:checked+span:after {
  border-color: $primary-color;
}

.checkbox-box span:before {
  top: 0px;
  content: "\F0E1E";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0px;
  z-index: 9;
  margin: 0;
  opacity: 0;
  text-align: center;
  line-height: 25px;
  font: normal normal normal 24px/1"Material Design Icons";
  font-size: 16px;
  line-height: 20px;
  color: #28a745;
}

.checkbox-box input[type=checkbox]:checked+span:before {
  opacity: 1;
}

.checkbox-false span:before {
  content: "\F1398";
  color: #ff3d60 !important;
}

.action-checkbox {
  display: flex;
}

/***** End Checkbox *****/

/*****  start Switch *****/

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin: 0px;
}

.switch input {
  display: none;
}

.switch .slider.round {
  border-radius: 26px;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

.switch .slider.round:before {
  border-radius: 50%;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: $white-color;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.slider {
  background-color: #28a745;
}

.switch .checkbox label::after {
  width: auto;
}

/***** End Switch *****/

/*****  start Choose file upload  *****/

.custom_file input {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .94rem + 2px);
  margin-bottom: 0;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  outline: none;
}

.custom_file input::-webkit-file-upload-button {
  border: 0px !important;
  background: #ced4da !important;
  height: 37px !important;
  outline: none;
}

.file_img {
  height: 35px;
  width: 35px;
  border-radius: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.custom_img_input input {
  width: calc(100% - 35px);
  margin-right: 10px;
}

.custom_img_input {
  display: flex;
}

/***** End Choose file upload *****/

/*****  start Alert Popup  *****/

.ion-overlay-wrapper {
  border-radius: 13px;
  overflow: hidden;
  display: flex;
  background: #f9f9f9;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 250px;
  display: inline-block;
  width: 250px;
  font-family: Poppins, sans-serif;
}

.sc-ion-alert-ios-s {
  height: 100%;
  width: 100%;
}

.alert-head.sc-ion-alert-ios {
  padding-top: 12px;
  padding-bottom: 7px;
  text-align: center;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

.alert-head.sc-ion-alert-ios h2 {
  margin-top: 8px;
  color: #000;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  padding: 0px;
  margin-bottom: 0px;
}

.alert-message.sc-ion-alert-ios {
  -webkit-padding-start: 16px;
  padding-inline-start: 16px;
  -webkit-padding-end: 16px;
  padding-inline-end: 16px;
  padding-top: 0;
  padding-bottom: 21px;
  color: #000;
  font-size: 12px;
  text-align: center;
  font-family: Poppins, sans-serif;
}

button.alert-button {
  outline: none;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  min-width: 50%;
  height: 44px;
  border-top: 0.55px solid #0000003b;
  border-right: 0.55px solid #0000003b;
  background-color: transparent;
  color: $primary-color;
  font-size: 17px;
  /* overflow: hidden; */
  border-bottom: 0px !important;
  border-left: 0;
  font-weight: bold;
}

.alert-button-group.sc-ion-alert-ios {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  margin-right: unset;
  -webkit-margin-end: -0.55px;
  margin-inline-end: -0.55px;
  margin-right: -0.55px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

button.alert-button+button.alert-button {
  border-right: 0px !important;
  border-left: 0px !important;
}

.sc-ion-alert-ios-s .sc-ion-alert-ios-backdrop {
  background: #00000054;
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  height: 100%;
  width: 100%;
  z-index: +9999;
}

.alert_popup .modal-content {
  background: none;
  border: 0px;
}

/*****  End Alert Popup  *****/

/*****  start Sidebar menu *****/

#sidebar-menu ul li a {
  text-transform: capitalize;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  background: #293041;
  color: #d7e4ec;
}

.metismenu li.active a *,
.metismenu li:hover a *,
#sidebar-menu ul li ul.sub-menu li.active a {
  color: $white-color !important;
  font-weight:500;
}

.navbar-brand-box {
  text-align: center;
}
.badge {
  line-height: 15px;
  font-size: 12px;
}







@media (max-width: 992px) {
  .sidebar-enable .vertical-menu {
    display: block;
    width: 240px !important;
    left: 0px !important;
    transition: all 0.3s;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a span {
    display: contents;
    padding-left: 0px;
  }

  div#layout-wrapper .vertical-menu {
    display: block;
    left: -300px;
    transition: all 0.3s;
    z-index: 999;
    position: fixed;
  }

  body #layout-wrapper .navbar-brand-box {
    width: 73px !important;
    padding: 0px !important;
  }

  .navbar-brand-box {
    display: block !important;
  }

  .vertical-collpsed .footer {
    left: 0px !important;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {
    background: transparent !important;
    width: auto !important;
  }
}

/*****  End Sidebar menu  *****/

/***** pagination-template  *****/

pagination-template ul.ngx-pagination li {
  width: 34px;
  cursor: pointer !important;
  height: 34px;
  padding: 0px !important;
  text-align: center;
  line-height: 34px;
  border-radius: 60px !important;
  margin: 0 3px;
}

pagination-template ul.ngx-pagination li.pagination-next,
pagination-template ul li.pagination-previous span,
pagination-template ul li.pagination-next a,
pagination-template ul li.pagination-previous span,
pagination-template ul.ngx-pagination li.pagination-previous a {
  font-size: 0px !important;
}

.ngx-pagination .pagination-previous a:before,
.ngx-pagination .pagination-previous.disabled:before {
  content: "\F0141" !important;
  font-family: "Material Design Icons" !important;
}

pagination-template ul li.pagination-next a:after,
pagination-template ul.ngx-pagination li.pagination-previous a:before {
  font-size: .9rem;
}

pagination-template ul.ngx-pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
  padding: 0px;
  display: flex;
}

pagination-template ul.ngx-pagination li a {
  padding: 0px !important;
  display: inline-block;
  line-height: 25px;
}

pagination-template ul.ngx-pagination li a:after {
  line-height: 34px;
  margin: 0px !important;
}

pagination-template ul.ngx-pagination li a .show-for-sr {
  display: none;
}

pagination-template ul.ngx-pagination li.current {
  background-color: $primary-color;
  cursor: default !important;
}

pagination-template ul.ngx-pagination li:hover {
  background-color: $primary-color;
}

pagination-template ul.ngx-pagination li:hover a {
  background: no-repeat;
  color: $white-color;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  margin: 0px !important;
}

.ngx-pagination .pagination-next a:after,
.ngx-pagination .pagination-next.disabled:after {
  content: "\F0142" !important;
  font-family: "Material Design Icons" !important;
  font-size: 0.9rem;
}

/*****  End pagination  *****/

.auth-form-group-custom .form-control {
  padding-right: 60px;
}

.auth-form-group-custom .auti-custom-eye {
  left: auto;
  right: 19px;
  cursor: pointer;
}

.full-button {
  min-width: 100% !important;
}

body {
  overflow-x: hidden;
}

.sidebar-enable .navbar-brand-box {
  padding: 0 10px !important;
}

.copyright a {
  color: #505d69;
}

.password_box {
  position: relative;
}

.password_box .auti-custom-eye {
  left: auto;
  right: 12px;
  cursor: pointer;
  color: $primary-color !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
}
.password_box input.form-control {
  padding-right: 38px;
}
/***** Colors *****/

.custom-control-input:checked~.custom-control-label::before,
.page-item.active .page-link {
  border-color: $primary-color;
  background-color: $primary-color;
}

.btn-primary {
  background: $primary-color !important;
  border-color: $primary-color !important;
}

.text-primary,
body a.text-primary:focus,
body a.text-primary:hover,
.text-solitud:hover {
  color: $primary-color !important;
}

.auth-form-group-custom .auti-custom-input-icon {
  color: $primary-color !important;
}

.btn-warning,
.custom-accordion-arrow .card-header .accor-arrow-icon {
  box-shadow: none !important;
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

/***** apexcharts *****/
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}

.dropdown-item:not([href]),
a:not([href]) {
  cursor: pointer;
  color: #212529;
}

.float-right .custom-select {
  z-index: +99;
  position: relative;
  cursor: pointer;
}

.apexcharts-legend.position-bottom.apexcharts-align-center {
  margin-top: 10px !important;
}

.apx_chart {
  float: left;
  width: 100%;
}

.btn-w {
  padding: 5px 20px;
  margin-left: 15px;
}

.btn_style {
  background: none;
  border: 0px;
  padding: 0px;
}

.text-solitud {
  color: #808080;
}

.page-title-right2 {
  display: flex;
  align-items: center;
}

.page-title-box .breadcrumb * {
  font-weight: 500 !important;
  font-family: Inter, sans-serif;
}

.description-text {
  white-space: initial;
  font-size: 12px;
  max-width: 300px;
}

.img-circle {
  border-radius: 50%;
}

.profile-details {
  text-align: center;
}

.user-img {
  margin-bottom: 10px;
}

.select2-container {
  width: 100% !important;
}

.popup-w {
  max-width: 700px;
}

.note-editable.card-block {
  max-height: 200px !important;
}

.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: .5rem;
  font-size: .9rem !important;
  line-height: 1.5;
  color: #505d69;
}

.dataTables_length label .custom-select {
  width: auto;
  display: inline-block;
}

.dataTables_filter {
  text-align: right;
}

.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

.dataTables_filter label .form-control {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

.table-responsive>.row {
  margin: 0px;
}

.table-responsive>.row>div[class^="col-"]:first-child {
  padding-left: 0;
}

.table-responsive>.row>div[class^="col-"]:last-child {
  padding-right: 0px;
}

.popup-inner .card-title {
  font-weight: 500;
  text-transform: initial;
}

.table-responsive .table.mb-0 {
  margin-bottom: 15px !important;
}

.table-responsive .table.mb-0 {
  margin-bottom: 15px !important;
}

.table-responsive .dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap;
}

.table-responsive .paging_simple_numbers {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.table-responsive .paging_simple_numbers .pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}
.table_page .card-body .table th{vertical-align: top;}
.table_page .card-body .table th,
.table_page .card-body .table td {
  font-size: 12px;
  padding: 10px 5px;
  list-style: 15px;
}

.btn_style i {
  display: block;
}

.page-link:hover {
  color: $primary-color;
}

.filter_boxs {
  display: flex;
  justify-content: flex-end;
}

.dataTables_filter_select {
  margin-right: 15px;
  margin-bottom: .5rem;
}

.dataTables_filter_select .filter_select {
  display: inline-block;
  width: auto;
  border: 1px solid #ced4da;
  outline: none;
}

.button_detail {
  text-align: end;
}

.bg_img {
  height: 35px;
  width: 35px;
  border-radius: 60px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.user_img {
  display: inline-block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  padding-bottom: 50%;
  border-radius: 100%;
}

.btn_style,
.btn_style+.text-danger i:before,
.btn_style,
.btn_style+.text-danger i,
.btn_style {
  line-height: 18px;
}

/***************** Sub Menu *****************/

/**** User Details ****/

.basic_profile h6,
.basic_profile p {
  margin-bottom: 0px;
}

.basic_profile td:first-child {
  padding-left: 0;
  font-weight: 600;
  color: #000;
}

.basic_profile td:last-child {
  padding-right: 0;
}

.basic_profile td {
  font-size: 11px;
  padding: 10px 5px;
}

.basic_profile tr:first-child td {
  border-top: 0px;
}

.basic_profile p {
  font-size: 12px;
}

.basic_profile h6 {
  font-size: 12px;
}

.profile-details {
  position: relative;
}

.profile-details .switch_profile {
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-details .switch_profile .label-text {
  margin-bottom: 0px;
  padding-right: 5px;
  font-size: 13px;
}

/************************* Start custom css *************************/
.angular-editor-textarea {
  height: 350px !important;    outline: none;
}
// .angular-editor-toolbar-set:nth-child(2),
// .angular-editor-toolbar-set:nth-child(7),
// .angular-editor-toolbar-set:nth-child(8),
.angular-editor-toolbar-set:nth-child(11),
.angular-editor-toolbar-set:nth-child(9),
.angular-editor-toolbar-set:nth-child(7),button#subscript-editor1,button#superscript-editor1,
button#subscript-editor2,button#superscript-editor2,button#insertHorizontalRule-editor1, 
button#insertVideo-editor1, button#insertImage-editor1,button#insertHorizontalRule-editor2, 
button#insertVideo-editor2, button#insertImage-editor2,
button#strikeThrough-editor1, button#strikeThrough-editor2 {
    display: none !important;
}

.angular-editor-toolbar-set:last-child {
    margin-right: 0px !important;
}

// date_picker 21-07-2021
.date_picker {
  margin-bottom: 8px;
}

.date_picker .form-control,.date_picker .input-group {
  width: auto !important;
  display: inline-block;
}
.date_picker .calendar-icon {
  display: flex;
  align-items: center;
  font-size: 13px;
  // justify-content: center;
  // min-width: 120px; 
  cursor: pointer;
}

.date_picker .calendar-icon i {
  margin-left: 10px;
}

.date_picker .form-control {padding: 4px 12px;height: auto !important;}
.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  line-height: 14px !important;
}
.date_picker .form-control {
  font-size: 13px;
}

.showModal .table td, .showModal .table th {
  padding: 8px 5px;
  font-size: 12px !important;
}

.showModal button.btn {
  margin-left: 0pc;
  padding: 2px 10px;
  font-size: 10px;
}

.showModal .modal-title {
  font-size: 17px;
}
.slider-space  .ngx-slider .ngx-slider-pointer {width: 12px;height: 12px;top: -4px;background-color: #793dc7;outline: none !important;}
.slider-space .ngx-slider .ngx-slider-pointer:after { display: none;}
.slider-space .ngx-slider .ngx-slider-selection {background-color: #793dc7;}

.slider-space ngx-slider {
    margin: 0px 0 15px;
}
.slider-space .ngx-slider-bubble {
    top: auto;
    bottom: -22px !important;
    font-size: 12px !important;
}

 
.slider-space label {
    line-height: 7px;
    font-size: 11px;
    position: relative;
    top: -4px;
    margin-bottom: 0px;
}

.slider-space {
    width: 120px;
}
/**
* Responsive
**/
// @media (min-width: 992px) {
// 	.daterangepicker {
// 		left: 283px !important;
// 	}
	
// .daterangepicker.opensleft:after {
//     left: 10px !important;
//     right: auto !important;
// }

// .daterangepicker.opensleft:before {
//     left: 9px !important;
//     right: auto !important;
// }

// }
.apexcharts-toolbar {
  display: none !important;
}

@media (max-width: 1280px) {}

@media (min-width: 992px) {
  .chat-leftsidebar {
    min-width: 300px;
  }
}

@media (max-width: 992px) {
  .custom_file {
    margin-bottom: 1rem !important;
  }

  /**** Start custom css *****/
}

@media (max-width: 769px) {
  .table-responsive .table thead {
    display: none;
  }

  .table-responsive .table tbody td {
    display: block;
    text-align: end;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
  }

  .table-responsive .table tbody td .user-icon {
    float: none;
  }

  .table-responsive .table tbody td:before {
    content: attr(data-title)":";
    position: absolute;
    left: 5px;
    color: #505d69;
    font-weight: 600;
    font-family: Nunito, sans-serif;
  }

  .table-responsive .table tbody td[data-title="Labels"] {
    height: 40px;
  }

  .table-responsive .table tbody td:first-child {
    border-top: 0px;
  }

  .description-td .description-text {
    margin-left: auto;
  }

  .table-responsive .table tbody td:last-child {
    border-bottom: 1px solid #c9cbce;
  }

  .table-responsive .table tbody tr:last-child td:last-child {
    border-bottom: 0px;
  }

  .dataTables_length label,
  .dataTables_filter,
  .table-responsive .dataTables_info {
    text-align: center;
    width: 100%;
  }

  .table-responsive>.row>div[class^="col-"] {
    padding: 0px;
  }

  .filter_boxs {
    flex-wrap: wrap;
  }

  .dataTables_filter_select {
    margin-right: 0px;
    width: 100%;
    text-align: center;
  }

  .table-style td[data-title="Picture"],
  .table-style td[data-title="Name"] {
    width: 100%;
  }

  .table-responsive .table tbody td {
    // padding-left: 85px !important;
    padding-left: 110px !important;
    min-height: 43px;
    word-break: break-all;
  }

  /**** Start custom css *****/
  .date_picker {
    text-align: center;
}
  .input-group.bootstrap-touchspin {
    justify-content: flex-end;
  }

  tr.add-more td[data-title="Value"],
  tr.add-more td[data-title="Action"] {
    display: none;
  }

  .bg_img {
    margin-left: auto;
  }

  input.add-option,
  input.input-item {
    text-align: right !important;
  }

  .input-group.date_group {
    text-align: center;
    display: inline-block;
  }

  .tab-box td[style="width: 130px;"] {
    width: 100% !important;
    border-top: 1px solid #eff2f7 !important;
    min-height: 39px !important;
  }

  .tab-box td[style="width: 30px;"] {
    width: 100% !important;
    border-bottom: 1px solid #eff2f7 !important;
    min-height: 39px !important;
  }
  .filter-all{flex-wrap: wrap;}
  .filter-all .mr-2 {
    margin-bottom: 10px;
}
}

@media (max-width: 479px) {
  .filter_s {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .filter_s .select_box_text {
    width: 100%;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }

  .filter_s .select_box select {
    font-size: 10px;
  }

  .filter_s .ml-3 {
    margin-left: 0px !important;
  }

  /**** Start custom css *****/
  .price {
    justify-content: flex-end;
}

ul.description-table {
    text-align: left;
}
.picture-row .col-img {
  width: 50% !important;
}
.showModal input.form-control {
  max-width: 130px;
}
}

@media (max-width: 369px) {
  .table-responsive .table tbody td {
    // padding-left: 115px;
    font-size: 13px;
  }

  .filter_s .select_box select {
    margin-bottom: 10px;
    line-height: 18px;
  }

  .filter_s .select_box+.select_box {
    float: left;
    width: calc(50% - 5px);
  }

  .filter_s .select_box:last-child {
    margin-left: 5px;
  }

  /**** Start custom css *****/
}

.api-inline-loader {
  display: block;
  text-align: center;
  margin-top: 30px;

   
}

.loader-table {
  overflow: hidden;
}